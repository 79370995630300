import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const Login = ({ setPw }) => {
	const [password, setpassword] = useState('');
	const handleSubmit = (e) => {
		e.preventDefault();
		setPw(password);
		setpassword('');
	};
	return (
		<div css={tw`p-6 max-w-sm mx-auto`}>
			<form css={tw`rounded`} onSubmit={handleSubmit}>
				<div css={tw`mt-6 mb-4`}>
					<label htmlFor="password" css={tw`block text-sm font-medium leading-5 text-gray-700`}>
						Password
					</label>
					<div css={tw`mt-1 rounded-md shadow-sm`}>
						<input
							id="password"
							type="password"
							required
							css={tw`p-2 border-solid border-grey focus:border-blue tracking-wide rounded outline-none text-base`}
							value={password}
							onChange={(e) => setpassword(e.target.value)}
						/>
					</div>
				</div>
				<button
					type="submit"
					css={tw`text-lg border-none outline-none bg-blue text-white rounded mx-auto cursor-pointer hover:bg-blue-dark py-1 px-3 shadow-sm mw-16`}
				>
					Log in
				</button>
			</form>
		</div>
	);
};

Login.propTypes = {
	setPw: PropTypes.func.isRequired,
};

export default Login;
