import React, { useState, useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import { API } from 'aws-amplify';

// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import Switch from 'react-switch';
import Input from '../components/styled/Input';
import Button from '../components/styled/Button';
import { formatPrice } from '../helpers';

API.configure();

const processOrder = async (body) => {
	const apiName = 'skyapi';
	const path = '/process-order';
	return await API.post(apiName, path, { body });
};

const Order = ({ history }) => {
	const [order, setOrder] = useGlobal('order');
	const [client] = useGlobal('client');
	const cart = get(order, `${client}`, {});

	const [, setNotification] = useGlobal('notification');
	const [, setLoading] = useGlobal('loading');
	const [remember, setRemember] = useState(!!window.localStorage.getItem('remember') || false);

	const [orgName, setOrgName] = useState(window.localStorage.getItem('orgName') || '');
	const [contactName, setContactName] = useState(window.localStorage.getItem('contactName') || '');
	const [email, setEmail] = useState(window.localStorage.getItem('email') || '');
	const [phone, setPhone] = useState(window.localStorage.getItem('phone') || '');

	const toDisplay = [];
	forOwn(cart, ({ qty, name, price }, productId) => toDisplay.push({ price, productId, qty, name }));

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		if (remember) {
			// Save all details to local storage
			window.localStorage.setItem('orgName', orgName);
			window.localStorage.setItem('contactName', contactName);
			window.localStorage.setItem('email', email);
			window.localStorage.setItem('phone', phone);
			window.localStorage.setItem('remember', 'true');
		} else {
			// Ensure local storage is clear
			window.localStorage.removeItem('orgName');
			window.localStorage.removeItem('contactName');
			window.localStorage.removeItem('email');
			window.localStorage.removeItem('phone');
			window.localStorage.removeItem('remember');
		}
		processOrder({ order: cart, orgName, contactName, email, phone })
			.then((res) => {
				setLoading(false);
				console.log(res);
				setOrder({ ...order, [client]: {} });
				setNotification({ title: 'Order sent!', message: `Please check ${email} for a confirmation` });
				history.push('/');
			})
			.catch((err) => {
				setNotification({ title: 'Error!', message: `Please contact us directly via phone/email` });
				setLoading(false);
				console.error(err);
			});
	};

	return (
		<form css={tw`max-w-lg`}>
			<h1>Order</h1>
			<p>Please double check the items in your order, fill in your details and then submit the order</p>
			<table css={tw`border-solid border border-gray-600 p-2 mb-4`}>
				<thead>
					<tr css={tw`py-3`}>
						<th css={tw`p-2 mr-6`}>Item</th>
						<th css={tw`p-2 mr-6`}>≈ Unit price</th>
						<th css={tw`p-2`}>Quantity on order</th>
					</tr>
				</thead>
				<tbody>
					{toDisplay.map(({ price, qty, name, productId }) => (
						<tr key={productId}>
							<td css={tw`p-2 mr-6`}>{name}</td>
							<td css={tw`p-2 mr-6`}>{formatPrice(price)}</td>
							<td align="right" style={{ alignText: 'right' }} css={tw`p-2`}>
								{qty}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<h2>Your details</h2>
			<Input label="Pharmacy/business name" value={orgName} onChange={({ target: { value } }) => setOrgName(value)} />
			<Input label="Your name" value={contactName} onChange={({ target: { value } }) => setContactName(value)} />
			<Input label="Email address" value={email} onChange={({ target: { value } }) => setEmail(value)} />
			<Input label="Phone number" value={phone} onChange={({ target: { value } }) => setPhone(value)} />
			<div css={tw`mt-4`}>
				<label css={tw`flex flex-row`}>
					<span css={tw`mr-3`}>Remember me on this browser/device</span>
					<Switch onChange={() => setRemember(!remember)} checked={remember} height={20} />
				</label>
			</div>
			<div css={tw`flex flex-row justify-between my-6`}>
				<Link to="/cart" css={tw`mr-3`}>
					<Button onClick={() => console.log('Click')}>Edit order</Button>
				</Link>
				<Button onClick={handleSubmit}>Submit order</Button>
			</div>
		</form>
	);
};

export default Order;
