import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
// import { clients } from '../index';

const Navbar = () => {
	const [order] = useGlobal('order');
	const [client] = useGlobal('client');
	const [, setPw] = useGlobal('pw');
	// const [dropdownOpen, setDropdownOpen] = useState(false);
	const cart = get(order, `${client}`, {});
	const cartCount = Object.keys(cart).length;
	// If multiple clients, then remove this hard coding
	// const clientIndex = clients.findIndex(({ routePath }) => routePath === client);
	// const clientIndex = 0;
	// const dropdownOptions = clients.filter( ( _el, i ) => i !== clientIndex );
	// const { clientName } = clients[clientIndex];

	return (
		<div css={tw`flex justify-between`}>
			<div css={tw`flex mx-6 text-lg`}>
				<Link css={tw`mx-3 no-underline`} to="/">
					<p css={tw`text-blue hover:text-blue-darker`}>Search items</p>
				</Link>
				<Link css={tw`mx-3 no-underline`} to="/cart">
					<p css={tw`text-blue hover:text-blue-darker`}>Cart ({cartCount})</p>
				</Link>
				<a
					css={tw`mx-3 no-underline cursor-pointer`}
					onClick={() => {
						setPw('');
					}}
					href="/#"
				>
					<p css={tw`text-blue hover:text-blue-darker`}>Sign out</p>
				</a>
			</div>
			{/* Dropdown if multiple clients are involved */}
			{/* <div css={tw`flex mx-6 text-lg`}>
				<div css={tw`flex relative group`}>
					<div
						css={tw`flex items-center cursor-pointer text-blue border border-white border-b-0  group-hover:border-grey-light rounded-t-lg py-1 px-2`}
						onClick={() => setDropdownOpen(!dropdownOpen)}
					>
						{clientName}
						<svg css={tw`fill-current h-4 w-4`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
							<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
						</svg>
					</div>
					{dropdownOpen && (
						<div css={tw`absolute mt-10`}>
							{dropdownOptions.map(({ routePath, clientName: name }) => (
								<Link
									key={routePath}
									css={tw`flex mx-3 no-underline text-sm text-blue hover:text-blue-darker my-1`}
									to={routePath}
									onClick={() => setDropdownOpen(false)}
								>
									<p css={tw`my-1`}>{name}</p>
								</Link>
							))}
						</div>
					)}
				</div>
			</div> */}
		</div>
	);
};

export default Navbar;
