import React from 'reactn';
import { connectInfiniteHits } from 'react-instantsearch-dom';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import Hit from './Hit';

const InfiniteHits = ({ hits, hasMore, refine, showPrice }) => {
	return (
		<div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '1rem' }} css={tw`mt-6`}>
			{hits.map(({ objectID, ...props }) => (
				<Hit key={objectID} showPrice={showPrice} id={objectID} {...props} />
			))}
			{hasMore && (
				<button
					onClick={refine}
					css={tw`w-full cursor-pointer text-lg border-none outline-none bg-primary hover:bg-blue-dark rounded text-white py-2 px-4`}
				>
					Show more
				</button>
			)}
		</div>
	);
};

export default connectInfiniteHits(InfiniteHits);
