import React, { useGlobal } from 'reactn';
import toString from 'lodash/toString';
import omit from 'lodash/omit';
import get from 'lodash/get';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import QtyInput from './QtyInput';
import ItemName from './ItemName';

const CartItem = ({ productId, name, qty, price }) => {
	const [order, setOrder] = useGlobal('order');
	const [client] = useGlobal('client');
	const cart = get(order, `${client}`, {});
	const updateOrder = ({ target: { value } }) => {
		const newQty = Number(value.replace(/\D/g, ''));
		const origOrder = { ...order };
		if (!newQty || !newQty > 0) {
			// TODO Remove item from order
		} else {
			origOrder[client][productId] = { qty: newQty, name };
		}
		setOrder(origOrder);
	};
	const removeItem = () => {
		const newOrder = { ...order };
		const newCart = omit(cart, productId);
		newOrder[client] = newCart;
		setOrder(newOrder);
	};
	return (
		<div
			style={{ display: 'grid', gridTemplateColumns: '1fr auto', gridGap: '1rem', alignItems: 'center' }}
			css={tw`bg-white p-3`}
		>
			<ItemName name={name} price={price} showPrice />
			<QtyInput updateQty={updateOrder} qty={toString(qty)} onBtnClick={removeItem} btnText="Remove" />
		</div>
	);
};

export default CartItem;
