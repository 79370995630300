import React, { useGlobal, useEffect } from 'reactn';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

// Styling skeleton from here: https://tailwindcss.com/components/alerts

const Notification = () => {
	const [notification, setNotification] = useGlobal('notification');
	const { message, title, timeout = 5000 } = notification;
	useEffect(() => {
		// Condition necessary otherwise timeout function will cause notification to be set causing
		// useEffect and the timeout function to run again...infinite loop...
		if (message !== '') {
			setTimeout(() => {
				setNotification({ message: '' });
			}, timeout);
		}
	}, [notification, message, setNotification, timeout]);
	if (!notification) return null;
	if (!message) return null;
	return (
		<div
			css={tw`max-w-sm fixed pin-t pin-r mt-4 mr-4 bg-teal-lightest rounded text-teal-darker px-4 py-3 shadow-md `}
			role="alert"
		>
			<div css={tw`flex items-center`}>
				<div css={tw`py-1`}>
					<svg css={tw`fill-current h-6 w-6 text-teal mr-4`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
					</svg>
				</div>
				<div>
					{title && <p css={tw`font-bold`}>{title}</p>}
					<p css={tw`text-sm`}>{message}</p>
				</div>
				<span>
					<svg
						css={tw`fill-current h-6 w-6 text-teal-darker ml-4 mb-3 cursor-pointer`}
						onClick={() => setNotification({ message: '' })}
						role="button"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
					>
						<title>Close</title>
						<path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
					</svg>
				</span>
			</div>
		</div>
	);
};

export default Notification;
