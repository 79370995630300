import React, { useGlobal } from 'reactn';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

import AlgoliaSearchInput from '../components/algolia/AlgoliaSearchInput';
import Hits from '../components/algolia/Hits';
import Cart from '../components/Cart';

const searchClient = algoliasearch('YIIGB3L14S', '4f23eaa5489aff69d00e396c5aa2f483');

const SearchItems = ({ algoliaIndex, showPrice }) => {
	const [query] = useGlobal('query');
	return (
		<div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gridGap: '2rem', alignItems: 'start' }}>
			<div>
				<InstantSearch indexName={algoliaIndex} searchClient={searchClient}>
					<AlgoliaSearchInput />
					{query.length > 2 && <Hits showPrice={showPrice} />}
				</InstantSearch>
			</div>
			<div css={tw`hidden lg:block bg-grey-lighter border-blue-500 px-4 pb-6`}>
				<Cart />
			</div>
		</div>
	);
};

SearchItems.propTypes = {
	algoliaIndex: PropTypes.string.isRequired,
	showPrice: PropTypes.bool.isRequired,
};
export default SearchItems;
