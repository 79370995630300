import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const QtyInput = ({ updateQty, qty, btnText, onBtnClick }) => {
	const qtyInputEl = useRef(null);

	return (
		<div css={tw`text-lg`}>
			<input
				ref={qtyInputEl}
				type="tel"
				onChange={updateQty}
				value={qty}
				css={tw`p-2 border-solid border-grey focus:border-blue tracking-wide rounded outline-none mr-1 w-12 text-base`}
				placeholder="Qty"
				onFocus={() => qtyInputEl.current.select()}
			/>
			{/* <button>
          <svg viewBox="0 0 32 32" className="icon icon-chevron-top" viewBox="0 0 32 32" aria-hidden="true"><path d="M15.997 13.374l-7.081 7.081L7 18.54l8.997-8.998 9.003 9-1.916 1.916z"/></svg>
        </button>
				<button>Down</button> */}
			{onBtnClick && (
				<button
					css={tw`text-lg border-none outline-none bg-blue text-white rounded ml-4 cursor-pointer hover:bg-blue-dark py-1 mw-16`}
					onClick={onBtnClick}
				>
					{btnText}
				</button>
			)}
		</div>
	);
};

QtyInput.propTypes = {
	updateQty: PropTypes.func.isRequired,
	qty: PropTypes.string.isRequired,
	onBtnClick: PropTypes.func,
	btnText: PropTypes.string,
};

QtyInput.defaultProps = {
	onBtnClick: null,
	btnText: 'Add',
};

export default QtyInput;
