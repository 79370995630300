import React, { useEffect, useGlobal } from 'reactn';
import { Router, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import forOwn from 'lodash/forOwn';
import { SITEPASSWORD } from './index';
import SearchItems from './pages/SearchItems';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { clients } from './index';
import Cart from './components/Cart';
import Order from './pages/Order';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import Notification from './components/Notification';
import LoadingScreen from './components/LoadingScreen';
import Navbar from './components/Navbar';
import Login from './pages/Login';

Amplify.configure(awsmobile);
const history = createBrowserHistory();

// const Header = styled.header`
// 	${tw`bg-blue min-h-screen flex flex-col items-center justify-center text-xl text-white`};
// `;

// Clear local storage if not in the right shape
const clearLocalOrder = () => {
	const savedCart = JSON.parse(window.localStorage.getItem('order'));
	if (!savedCart) return;
	const clientPaths = clients.map(({ routePath }) => routePath);

	forOwn(savedCart, (value, key) => {
		if (!clientPaths.includes(key)) {
			window.localStorage.removeItem('order');
		}
	});
};

const App = () => {
	clearLocalOrder();
	const [hist, setHistory] = useGlobal('history');
	const [client, setClient] = useGlobal('client');
	const [pw, setPw] = useGlobal('pw');

	useEffect(() => {
		setHistory(history);
	}, [setHistory]);
	const clientPaths = clients.map(({ routePath }) => routePath);
	history.listen(({ pathname }) => {
		if (clientPaths.includes(pathname) && pathname !== client) {
			setClient(pathname);
		}
	});
	if (!hist.location) return null;
	return (
		<div className="App" css={tw`font-sans`}>
			{pw === SITEPASSWORD ? (
				<Router history={history}>
					<>
						<Notification />
						<LoadingScreen />
						<Navbar />
						<div css={tw`m-8 mw-lg`}>
							<Route exact path="/" render={() => <Redirect to="/sky" />} />
							<Route exact path="/cart" component={Cart} />
							<Route exact path="/order" component={Order} />
							{clients.map(({ clientName, routePath, algoliaIndex, showPrice }) => (
								<Route
									key={clientName}
									exact
									path={routePath}
									render={() => <SearchItems algoliaIndex={algoliaIndex} showPrice={showPrice} />}
								/>
							))}
						</div>
					</>
				</Router>
			) : (
				<Login setPw={setPw} />
			)}
		</div>
	);
};

export default App;
