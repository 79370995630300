import React, { useState, useGlobal } from 'reactn';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import toString from 'lodash/toString';
import get from 'lodash/get';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import QtyInput from '../QtyInput';
import ItemName from '../ItemName';

const InfiniteHits = props => {
	const { id, name, price } = props;
	const [order, setOrder] = useGlobal('order');
	const [client] = useGlobal('client');
	const cart = get(order, `${client}`, {});
	const initialQty = cart[id] === undefined ? 1 : cart[id].qty;
	const [qty, setQty] = useState(toString(initialQty));
	const inputQty = ({ target: { value } }) => {
		setQty(value.replace(/\D/g, ''));
	};
	const addToOrder = () => {
		const q = Number(qty);
		if (!q || !q > 0) return;
		const origOrder = { ...order };
		cart[id] = { qty: q, name, price };
		origOrder[client] = cart;
		setOrder(origOrder);
	};
	return (
		<div
			css={tw`p-4 flex flex-wrap justify-between max-w-lg text-lg w-full rounded border-bottom-solid border-b-1 bb-grey`}
		>
			<ItemName {...props} />
			<QtyInput updateQty={inputQty} qty={qty} onBtnClick={addToOrder} />
		</div>
	);
};

export default connectInfiniteHits(InfiniteHits);
