import React from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const Input = ({ label, value, onChange }) => {
	return (
		<div css={tw`tracking-wide mt-2 max-w-lg`}>
			<label css={tw`flex flex-wrap flex-row items-center justify-between`}>
      <span css={tw`w-64 mr-3 mb-2`}>
				{label}</span>
				<input value={value} onChange={onChange} css={tw`p-2 border-solid border-grey focus:border-blue rounded outline-none text-base w-96`}/>
			</label>
		</div>
	);
};

export default Input;
