import React, { useGlobal } from 'reactn'; // <-- reactn
import { connectSearchBox } from 'react-instantsearch-dom';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const AlgoliaSearchInput = ({ currentRefinement, refine }) => {
	const [, setQuery] = useGlobal('query');
	return (
		<input
			css={tw`block bg-grey-lighter placeholder-grey-darkest p-4 text-lg outline-none border-solid border-grey w-full rounded appearance-none leading-normal focus:border-blue tracking-wide`}
			type="search"
			value={currentRefinement}
			onChange={event => {
				const { value } = event.currentTarget;
				refine(value);
				setQuery(value);
			}}
			autoFocus
			placeholder="Search here"
		/>
	);
};

export default connectSearchBox(AlgoliaSearchInput);
