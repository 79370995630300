import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { formatPrice } from '../helpers';

const ItemName = ({ name, price, showPrice }) => {
	return (
		<div>
			<p css={tw`mb-1 mt-0`}>{name}</p>
			{/* <p css={tw`mt-1 mb-0 text-sm text-grey-dark`}>Item code: {id}</p> */}
			{showPrice && <p css={tw`mt-1 mb-0 text-md text-primary`}>≈ Unit price: {formatPrice(price)}</p>}
		</div>
	);
};

ItemName.propTypes = {
	price: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
};

export default ItemName;
