import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const Button = ({ children, size, bg, text, onClick }) => {
	return (
		<button
			css={tw`cursor-pointer text-lg border-none outline-none bg-primary hover:bg-blue-dark rounded text-white py-2 px-4`}
			onClick={onClick}
		>
			{children}
		</button>
	);
	// return <button css={tw`text-${size} border-none outline-none bg-${bg} rounded ml-4 text-${text}`}>{children}</button>;
};

Button.propTypes = {
	size: PropTypes.string,
	bg: PropTypes.string,
	text: PropTypes.string,
};

Button.defaultProps = {
	size: 'normal',
	bg: 'white',
	text: 'primary',
};

export default Button;
