import React, { setGlobal, addCallback } from 'reactn';
import ReactDOM from 'react-dom';
import App from './App';
import get from 'lodash/get';
import * as serviceWorker from './serviceWorker';

export const clients = [
	{
		algoliaIndex: 'items',
		clientName: 'Sky',
		routePath: '/sky',
		showPrice: true,
	},
	{
		algoliaIndex: 'usn',
		clientName: 'USN',
		routePath: '/usn',
		showPrice: true,
	},
];

export const SITEPASSWORD = 'Sky108';

const savedCart = JSON.parse(window.localStorage.getItem('order'));
const currentClient = JSON.parse(window.localStorage.getItem('client'));
const currentPw = window.localStorage.getItem('pw');
console.log(currentPw);

// Set an initial global state directly:
setGlobal({
	query: '',
	order: savedCart || {},
	x: 0,
	notification: {
		message: '',
		type: 'success',
	},
	loading: false,
	history: {},
	client: currentClient || '',
	pw: currentPw === SITEPASSWORD ? SITEPASSWORD : '',
});

addCallback((globalState) => {
	const { order, history, client, pw } = globalState;
	window.localStorage.setItem('order', JSON.stringify(order));
	window.localStorage.setItem('client', JSON.stringify(client));
	window.localStorage.setItem('pw', pw);
	const path = get(history, 'location.pathname', '');
	const clientPaths = clients.map(({ routePath }) => routePath);
	if (clientPaths.includes(path) && path !== client) {
		const newGlobal = { ...globalState, client: path };
		return newGlobal;
	}
	return null;
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
