import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
// eslint-disable-next-line no-unused-vars
import tw from 'tailwind.macro';
import forOwn from 'lodash/forOwn';
import CartItem from './CartItem';

const Cart = () => {
	const [order] = useGlobal('order');
	const [client] = useGlobal('client');
	const cart = get(order, `${client}`, {});
	// Create an array of all the items in the order
	const toDisplay = [];
	forOwn(cart, ({ qty, name, price }, productId) => toDisplay.push({ productId, qty, name, price }));
	return (
		<div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '1rem', minWidth: '33vw', maxWidth: '64rem' }}>
			<h2>Cart:</h2>
			{toDisplay.map((item) => (
				<CartItem key={item.productId} {...item} />
			))}
			<Link css={tw`flex mx-3 no-underline`} to="/order">
				<button
					css={tw`text-lg border-none outline-none bg-blue text-white rounded mx-auto cursor-pointer hover:bg-blue-dark py-1 mw-16`}
				>
					Finalise Order
				</button>
			</Link>
		</div>
	);
};

export default Cart;
